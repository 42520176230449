import * as React from "react";

import Layout from "../components/layout";
// import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout PageTitle="404">
    {/* <SEO title="404: Not found" /> */}
    <div className="jumbotron d-flex align-items-center min-vh-100">
      <div className="container text-center">
        <h1>Błąd 404</h1>
        <p>Wprowadzono niepoprawny adres URL.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
